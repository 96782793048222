import React, { useEffect } from 'react'
import styles from './Snackbar.module.scss'
import { motion } from 'framer-motion'

interface Props {
    text: string
    hideCallback: () => void
}

const variantsPages = ({
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
});

export default function Snackbar(props: Props) {

    useEffect(() => {
        const time = setTimeout(() => {
            props.hideCallback()
        }, 4000);
        return () => {
            clearTimeout(time)
        }
    }, [props])

    return (
        <motion.div className={styles.snackbar}
        initial="hidden" 
        animate="visible" 
        exit="hidden" 
        variants={variantsPages}
        transition={{ type: "spring", stiffness: 100, damping: 100 }}>
            <p>{props.text}</p>
            <button onClick={props.hideCallback}>Avfärda</button> 
        </motion.div>
        
    )
}
