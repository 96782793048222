import React, { useState } from 'react'
import './App.scss'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import ScreenCalculate from './screens/calculate/ScreenCalculate';
import ScreenResults from 'screens/results/ScreenResults';

const variantsPages = ({
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0.95 }
});

function App() {
    const [inputLength, setInputLength] = useState<string>("")
    const [inputWidth, setInputWidth] = useState<string>("")
    const [inputDepth, setInputDepth] = useState<string>("")
    const [inputPH, setInputPH] = useState<string>("")
    const [inputAlk, setInputAlk] = useState<string>("")
    const [inputChlorine, setInputChlorine] = useState<string>("")
    const [inputHardness, setInputHardness] = useState<string>("")
 
    const [chlorineType, setChlorineType] = useState("dags")

    return (
    <BrowserRouter>
        <Route render={(props: any) => (
            <AnimatePresence exitBeforeEnter initial={true}>
                <motion.div
                    className="screen"
                    initial="hidden" 
                    animate="visible" 
                    exit="hidden" 
                    variants={variantsPages}
                    transition={{ type: "spring", stiffness: 2000, damping: 100 }}
                    key={props.location.key}>

                    <Switch location={props.location}>
                        <Route exact path="/">
                            <ScreenCalculate 
                            setInputLength={setInputLength}
                            setInputWidth={setInputWidth}
                            setInputDepth={setInputDepth}
                            setInputPH={setInputPH}
                            setInputAlk={setInputAlk}
                            setInputChlorine={setInputChlorine}
                            setInputHardness={setInputHardness}
                            setChlorineType={setChlorineType}
                            length={inputLength}
                            width={inputWidth}
                            depth={inputDepth}
                            PH={inputPH}
                            alk={inputAlk}
                            chlorine={inputChlorine}
                            hardness={inputHardness}
                            chlorineType={chlorineType} />
                        </Route>
                        <Route path="/results">
                            <ScreenResults
                            length={inputLength}
                            width={inputWidth}
                            depth={inputDepth}
                            PH={inputPH}
                            alk={inputAlk}
                            chlorine={inputChlorine}
                            hardness={inputHardness}
                            chlorineType={chlorineType}/>
                        </Route>
                        <Redirect to="/"/>
                    </Switch>
                </motion.div>
            </AnimatePresence>
        )}/>
        
    </BrowserRouter>
  );
}

export default App;
