import React, { useEffect, useState } from 'react'
import styles from './ScreenCalculate.module.scss'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Dropdown, { Option } from 'components/Dropdown'
import Snackbar from 'components/Snackbar'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next';

interface Props extends RouteComponentProps {
    setInputLength: (length: string) => void
    setInputWidth: (width: string) => void
    setInputDepth: (depth: string) => void
    setInputPH: (ph: string) => void
    setInputAlk: (alk: string) => void
    setInputChlorine: (chlorine: string) => void
    setInputHardness: (hardness: string) => void
    setChlorineType: (type: string) => void
    length: string
    width: string
    depth: string
    PH: string
    alk: string
    chlorine: string
    hardness: string
    chlorineType: string
}

function ScreenCalculate(props: Props) {
    const { t } = useTranslation()
    const [snackbarText, setSnackbarText] = useState("")
    
    const chlorineOptions: Option[] = [
        {value: "dags", label: t("day_chlorine")},
        {value: "chock", label: t("shock_chlorine")}
    ]

    useEffect(() => {
        // Set previous pool sizes
        try {
            const poolSizes = localStorage.getItem("pool_sizes")
            if (poolSizes) {
                console.log(poolSizes)
                const length = parseFloat(poolSizes.split(':')[0])
                const width = parseFloat(poolSizes.split(':')[1])
                const depth = parseFloat(poolSizes.split(':')[2])
                props.setInputLength(length.toString())
                props.setInputWidth(width.toString())
                props.setInputDepth(depth.toString())
            }
        } catch { }
    }, [])

    function handleInputChange(event: any, type: string) {
        if (!/^\d*[.,]?\d*$/.test(event.target.value)) return
        switch (type) {
            case "length": props.setInputLength(event.target.value)
                break
            case "width": props.setInputWidth(event.target.value)
                break
            case "depth": props.setInputDepth(event.target.value)
                break
            case "ph": props.setInputPH(event.target.value)
                break
            case "alk": props.setInputAlk(event.target.value)
                break
            case "chlorine": props.setInputChlorine(event.target.value)
                break
            case "hardness": props.setInputHardness(event.target.value)
                break
        }
    }

    function handleBtnClick(event: any) {
        event.preventDefault()
        const inputs = [props.length, props.width, props.depth, props.PH, props.alk, props.chlorine, props.hardness]
        const allIsFilled = inputs.every(input => {
            return input.length > 0
        })
        if (!allIsFilled) {
            // Display error
            setSnackbarText(t("error_fill_fields"))
            return
        }
        try {
            // Redirect with values
            const length = props.length!.replace(",", ".")
            const width = props.width!.replace(",", ".")
            const depth = props.depth!.replace(",", ".")
            const ph = props.PH!.replace(",", ".")
            const alk = props.alk!.replace(",", ".")
            const chlorine = props.chlorine!.replace(",", ".")
            const hardness = props.hardness!.replace(",", ".")

            if (isNaN(parseFloat(length)) || isNaN(parseFloat(width)) || isNaN(parseFloat(depth))
                || isNaN(parseFloat(ph)) || isNaN(parseFloat(alk)) || isNaN(parseFloat(chlorine)) || isNaN(parseFloat(hardness))) {
                    setSnackbarText(t("error_general"))
                return
            }
            // Set pool size defaults
            localStorage.setItem("pool_sizes", length + ":" + width + ":" + depth)

            // Send to results
            props.history.push("/results")
            
        } catch {
            // Display error
            setSnackbarText(t("error_general"))
        }
    }
    return (
        <div className="containerMain">
            <AnimatePresence>
                {snackbarText.length > 0 && 
                    <Snackbar text={snackbarText} hideCallback={() => { setSnackbarText("") }}/>
                }
            </AnimatePresence>
            <div className="card">
                <form className={styles.content}>
                    <h2>{t("dimensions")}</h2>
                    <div className={styles.horizontal}>    
                        <div>
                            <label htmlFor="length">{t("length")}</label>
                            <input inputMode="numeric" autoComplete="off" onChange={(event: any) => { handleInputChange(event, "length")}} value={props.length} required maxLength={6} id="length" className={styles.input}/>
                        </div>
                        <div>
                            <label htmlFor="width">{t("width")}</label>
                            <input inputMode="numeric" autoComplete="off" onChange={(event: any) => { handleInputChange(event, "width")}} value={props.width} required maxLength={6} id="width" className={styles.input}/>
                        </div>
                        <div>
                            <label htmlFor="depth">{t("depth")}</label>
                            <input inputMode="numeric" autoComplete="off" onChange={(event: any) => { handleInputChange(event, "depth")}} value={props.depth} required maxLength={6} id="depth" className={styles.input}/>
                        </div>
                    </div>

                    <h2>{t("values")}</h2>
                    <div className={styles.horizontal}>    
                        <div>
                            <label htmlFor="ph">pH</label>
                            <input inputMode="numeric" autoComplete="off" onChange={(event: any) => { handleInputChange(event, "ph")}} value={props.PH} required maxLength={6} id="ph" className={styles.input}/>
                        </div>
                        <div>
                            <label htmlFor="alk">{t("alkal")}</label>
                            <input inputMode="numeric" autoComplete="off" onChange={(event: any) => { handleInputChange(event, "alk")}} value={props.alk} required maxLength={6} id="alk" className={styles.input}/>
                        </div>
                        <div>
                            <label htmlFor="hardness">{t("hardness")}</label>
                            <input inputMode="numeric" autoComplete="off" onChange={(event: any) => { handleInputChange(event, "hardness")}} value={props.hardness} required maxLength={6} id="hardness" className={styles.input}/>
                        </div>
                    </div>
                    <div className={styles.horizontal}>   
                        <div>
                            <label htmlFor="chlorine">{t("chlorine")}</label>
                            <input inputMode="numeric" autoComplete="off" onChange={(event: any) => { handleInputChange(event, "chlorine")}} value={props.chlorine} required maxLength={6} id="chlorine" className={styles.input}/>
                        </div>
                        <div className={styles.type}>
                            <label htmlFor="type">{t("chlorine_type")}</label>
                            <Dropdown value={props.chlorineType} options={chlorineOptions} onChange={(option: Option) => {props.setChlorineType(option.value)}} />
                        </div>
                        <div className={styles.placeholder}></div>
                    </div>
                </form>
                <button onClick={handleBtnClick}>{t("calculate")}</button>
            </div>
            <div className="card">
                <h2>{t("information")}</h2>
                <p>{t("dimens_info")}</p>
                <p>{t("alk_chlorine_info")}</p>
                <p>{t("hardness_info")}</p>
                <a href="https://www.pahlen.se/poolguiden/" className={styles.link}>{t("poolguide_info")}</a>
            </div>
        </div>
    )
}
export default withRouter(ScreenCalculate)