import React, { useEffect, useState } from 'react'
import styles from './ScreenResults.module.scss'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

interface Result {
    type: string
    text: string
    extra: string
}
interface Props extends RouteComponentProps {
    length: string
    width: string
    depth: string
    PH: string
    alk: string
    chlorine: string
    hardness: string
    chlorineType: string
}
function ScreenResults(props: Props) {
    const { t } = useTranslation()
    const [results, setResults] = useState<Array<Result>>()

    useEffect(() => {
        if (!props.length || !props.width || !props.depth || !props.PH || !props.alk || !props.chlorine || !props.hardness) {
            props.history.push("/")
        } else {
            calculateResults(parseFloat(props.length) * parseFloat(props.width) * parseFloat(props.depth), parseFloat(props.PH), parseFloat(props.alk), parseFloat(props.chlorine), parseFloat(props.hardness))
        }
    }, [props])

    function calculateResults(volume: number, ph: number, alk: number, chlorine: number, hardness: number) {
        const idealPH = 7.4
        const idealAlk = 100
        const idealChlorine = 1
        const idealHardness = 250
        const errorPH = idealPH - ph
        const errorAlk = idealAlk - alk
        const errorChlorine = idealChlorine - chlorine
        const errorHardness = idealHardness - hardness

        const results = []
        
        let alkOrHardnessChanged = false
        // Hardness
        if (errorHardness > 50) {
            const hardnessPlus = Math.round(errorHardness * (0.00154 * volume) * 1000)
            const result: Result = {type: t("add"), text: hardnessPlus + "g" + t("calcium_chloride"), extra: ""}
            results.push(result)
            alkOrHardnessChanged = true
        } else if (errorHardness < -50) {
            const result: Result = {type: t("dilute"), text: t("dilute_long"), extra: t("four_hours")}
            results.push(result)
            alkOrHardnessChanged = true
        }
        
        // Alk
        if (errorAlk > 20) {
            const alkPlus = Math.round(errorAlk * (0.00165 * volume) * 1000)
            const result: Result = {type: t("add"), text: alkPlus + "g" + t("sodium_bicarbonate"), extra: t("small_amounts")}
            results.push(result)
            alkOrHardnessChanged = true
        }
        
        if (alkOrHardnessChanged) {
            setResults(results)
            return
        }

        // PH
        let PHChanged = false
        if (errorPH > 0.1) {
            const phPlus = Math.round(errorPH * (0.05 * volume) * 1000)
            const result: Result = {type: t("add"), text: phPlus + "g" + " pH-Plus", extra: ""}
            results.push(result) 
            PHChanged = true
        } else if (errorPH < -0.1) {
            const phMinus = Math.round((errorPH - errorPH - errorPH) * (0.05 * volume) * 1000)
            const result: Result = {type: t("add"), text: phMinus + "g" + " pH-Minus", extra: ""}
            results.push(result) 
            PHChanged = true
        }
        if (PHChanged) {
            setResults(results)
            return
        }

        // Chlorine
        if (errorChlorine > 0.5) {
            const chlorinePlus = Math.round(errorChlorine * 1.5 * volume)
            if (props.chlorineType === "chock") {
                const result: Result = {type: t("add"), text: chlorinePlus + "g" + t("shock_chlorine_2"), extra: ""}
                results.push(result) 
            } else if (props.chlorineType === "dags") {
                const result: Result = {type: t("add"), text: chlorinePlus + "g" + t("day_chlorine_2"), extra: ""}
                results.push(result) 
            }
        } else if (errorChlorine < -0.5) {
            const result: Result = {type: t("wait"), text: t("chlorine_too_high"), extra: ""}
            results.push(result) 
        }
        
        if (results.length != 0) {
            setResults(results)
        }
    }

    return (
        <div className="containerMain">
            <div className="card">
                <div className={styles.content}>
                    <div className={styles.toolbar}>
                        <div className={styles.iconClick} onClick={() => props.history.push("/")}>
                            <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
                        </div>
                        <h1>{t("results")}</h1>
                    </div>
                    {results ? 
                    <div>
                        {results.map((result) => {
                            return (
                                <div className={styles.result}>
                                    <p className={styles.type}>{result.type}</p>
                                    <p className={styles.text}>{result.text}</p>
                                    <p className={styles.product}>{result.extra}</p>
                                </div>
                            )
                        })}
                        <p className={styles.center}>{t("new_measurements")}</p>
                    </div>
                    :
                        <p>{t("no_actions")}</p>
                    }
                </div>
            </div>
            <div className="card">
                <h2>{t("your_dimens")}</h2>
                <p>{props.length + " x " + props.width + " x " + props.depth + " m"}</p>
                <h2>{t("your_values")}</h2>
                <p>pH: {props.PH}</p>
                <p>{t("alkal")}: {props.alk} ppm</p>
                <p>{t("chlorine")}: {props.chlorine} ppm</p>
                <p>{t("hardness")}: {props.hardness} ppm (mg/L)</p>
            </div>
        </div>
    )
}
export default withRouter(ScreenResults)